import "./global.css";
import "./home.css";
import { Curtains, Vec2, Plane, PingPongPlane, ShaderPass, FXAAPass } from "curtainsjs";
import { TextTexture } from "./components/TextTexture";
import {
  planeFragmentShader,
  planeVertexShader,
  flowmapFragmentShader,
  flowmapVertexShader,
  displacementFragmentShader,
  displacementVertexShader,
} from "./components/shaders";

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// fixes 100vh bug on mobile devices
if (isMobile) {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", "-webkit-fill-available");
}

const loadWebGL = () => {
  // set up our WebGL context and append the canvas to our wrapper
  const curtains = new Curtains({
    container: "canvas",
    pixelRatio: Math.min(1.5, window.devicePixelRatio), // limit pixel ratio for performance
    watchScroll: false,
    production: true,
  });

  curtains
    .onError(() => {
      // we will add a class to the document body to display original images
      document.body.classList.add("no-curtains", "planes-loaded");
      console.log("error");
    })
    .onContextLost(() => {
      // on context lost, try to restore the context
      curtains.restoreContext();
    });

  const mouse = new Vec2();
  const lastMouse = mouse.clone();
  const velocity = new Vec2();

  // we will keep track of all our planes in an array
  const planes = [];

  // get our planes elements
  const planeElements = document.getElementsByClassName("plane");
  const textPlaneElements = document.getElementsByClassName("text-plane");

  const planeParams = {
    vertexShader: planeVertexShader,
    fragmentShader: planeFragmentShader,
    widthSegments: 10,
    heightSegments: 10,
    uniforms: {
      time: {
        name: "uTime",
        type: "1f",
        value: 0,
      },
      fullscreenTransition: {
        name: "uTransition",
        type: "1f",
        value: 0,
      },
      mousePosition: {
        name: "uMousePosition",
        type: "2f",
        value: mouse,
      },
    },
  };

  // handle all the planes
  const handlePlanes = (index) => {
    const plane = planes[index];

    plane.onReady(() => {
      // once everything is ready, display everything
      if (index === planes.length - 1) {
        document.body.classList.add("planes-loaded");
      }
    });
    // .onRender(() => {
    //   plane.uniforms.time.value++;
    // });
  };

  // add our planes and handle them
  for (let i = 0; i < planeElements.length; i++) {
    const plane = new Plane(curtains, planeElements[i], planeParams);

    planes.push(plane);

    handlePlanes(i);
  }

  for (let i = 0; i < textPlaneElements.length; i++) {
    const textPlane = new Plane(curtains, textPlaneElements[i], planeParams);

    const textTexture = new TextTexture({
      plane: textPlane,
      textElement: textPlane.htmlElement,
      sampler: "planeTexture",
      resolution: 1.5,
      skipFontLoading: true, // we've already loaded the fonts
      watchScroll: false,
    });
  }

  /*** POST PROCESSING ***/
  // we'll be adding a flowmap rgb shift effect and fxaapass

  // mouse/touch move
  const onMouseMove = (e) => {
    // velocity is our mouse position minus our mouse last position
    lastMouse.copy(mouse);

    // touch event
    if (e.targetTouches) {
      mouse.set(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
    }
    // mouse event
    else {
      mouse.set(e.clientX, e.clientY);
    }

    // divided by a frame duration (roughly)
    velocity.set((mouse.x - lastMouse.x) / 16, (mouse.y - lastMouse.y) / 16);

    // we should update the velocity
    updateVelocity = true;
  };

  // only listen for mouse move when scroll is at the top
  const handleMouseMove = (e) => {
    if (window.scrollY === 0) {
      onMouseMove(e);
    }
  };
  window.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("touchmove", handleMouseMove, { passive: true });

  // if we should update the velocity or not
  let updateVelocity = false;

  // creating our PingPongPlane flowmap plane
  const bbox = curtains.getBoundingRect();

  const cursorParams = {
    cursorSize: isMobile ? 0.09 : 0.06,
    dissipation: 0.975,
    distortion: 1.2,
    red: 0,
    green: 0,
    blue: 0,
    mixValue: 12.8,
  };

  const getFlowMapParams = () => {
    return {
      sampler: "uFlowMap",
      vertexShader: flowmapVertexShader,
      fragmentShader: flowmapFragmentShader,
      watchScroll: false, // position is fixed
      texturesOptions: {
        floatingPoint: "half-float", // use half float texture when possible
      },
      uniforms: {
        mousePosition: {
          name: "uMousePosition",
          type: "2f",
          value: mouse,
        },
        // size of the cursor
        fallOff: {
          name: "uFalloff",
          type: "1f",
          value: cursorParams.cursorSize,
        },
        // alpha of the cursor
        alpha: {
          name: "uAlpha",
          type: "1f",
          value: 1,
        },
        // how much the cursor must dissipate over time (ie trail length)
        // closer to 1 = no dissipation
        dissipation: {
          name: "uDissipation",
          type: "1f",
          value: cursorParams.dissipation,
        },
        // our velocity
        velocity: {
          name: "uVelocity",
          type: "2f",
          value: velocity,
        },
        aspect: {
          name: "uAspect",
          type: "1f",
          value: bbox.width / bbox.height,
        },
        // multiplier for the distortion velocity
        distortionSpeed: {
          name: "uDistortionSpeed",
          type: "1f",
          value: cursorParams.distortion,
        },
      },
    };
  };

  // our ping pong plane
  const flowMap = new PingPongPlane(curtains, curtains.container, getFlowMapParams());

  flowMap
    .onRender(() => {
      // update mouse position
      flowMap.uniforms.mousePosition.value = flowMap.mouseToPlaneCoords(mouse);

      // update velocity
      if (!updateVelocity) {
        velocity.set(curtains.lerp(velocity.x, 0, 0.5), curtains.lerp(velocity.y, 0, 0.5));
      }
      updateVelocity = false;

      flowMap.uniforms.velocity.value = new Vec2(curtains.lerp(velocity.x, 0, 0.1), curtains.lerp(velocity.y, 0, 0.1));
    })
    .onAfterResize(() => {
      // update our window aspect ratio uniform
      const boundingRect = flowMap.getBoundingRect();
      flowMap.uniforms.aspect.value = boundingRect.width / boundingRect.height;
    });

  // now use the texture of our ping pong plane in the plane that will actually be displayed
  const planeUniforms = {
    red: {
      name: "uRed",
      type: "1f",
      value: cursorParams.red,
    },
    green: {
      name: "uGreen",
      type: "1f",
      value: cursorParams.green,
    },
    blue: {
      name: "uBlue",
      type: "1f",
      value: cursorParams.blue,
    },
    mixValue: {
      name: "uMixValue",
      type: "1f",
      value: cursorParams.mixValue,
    },
  };

  const passParams = {
    vertexShader: displacementVertexShader,
    fragmentShader: displacementFragmentShader,
    uniforms: planeUniforms,
    depth: false, // explicitly disable depth for the ripple effect to work
  };

  const shaderPass = new ShaderPass(curtains, passParams);

  // create a texture that will hold our flowmap
  const flowTexture = shaderPass.createTexture({
    sampler: "uFlowTexture",
    floatingPoint: "half-float",
    fromTexture: flowMap.getTexture(), // set it based on our PingPongPlane flowmap plane's texture
  });

  // wait for our first pass and the flowmap to be ready
  flowTexture.onSourceUploaded(() => {
    const fxaaPass = new FXAAPass(curtains);
  });

  window.addEventListener("scroll", () => {
    const scroll = window.scrollY;
    const canvasElement = document.getElementById("canvas");

    // if the page has scrolled past the canvas, hide the canvas and pause the rendering
    if (scroll > canvasElement.clientHeight) {
      canvasElement.style.opacity = 0;
      curtains.disableDrawing();
    }

    // if the page has scrolled back to the canvas, show the canvas and resume the rendering
    if (scroll < canvasElement.clientHeight) {
      canvasElement.style.opacity = 1;
      curtains.enableDrawing();
    }
  });
};

window.addEventListener("load", () => {
  loadWebGL();
});
